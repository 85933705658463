import React from "react";

const TermsAndConditions = ({ darkTheme }) => {
  return (
    <div
      id="terms-policy"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
          className={
            "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
          }
        >
          <div className="modal-header">
            <h3 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              Impressum
            </h3>
            <button
              type="button"
              className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">

          <h4>Angaben gem&auml;&szlig; &sect; 5 TMG</h4>
<p>Dr. Christian Zilz<br />
Ganghoferstr. 7<br />
85521 Ottobrunn</p>

<h4>Kontakt</h4>
<p>E-Mail: info@drzilz.de</p>

<h4>Berufsbezeichnung und berufsrechtliche Regelungen</h4>
<p>Berufsbezeichnung:<br />
Arzt</p>
<p>Verliehen in: Deutschland<br />
</p>
<p>Zust&auml;ndige Kammer:<br />
<a href="https://www.blaek.de" target="_blank" rel="noopener noreferrer">Landesärztekammer Bayern</a><br />
</p>
<p>Es gelten folgende berufsrechtliche Regelungen:<br />
<a href="//www.blaek.de/kammerrecht/berufsordnung-fuer-die-aerzte-bayerns" target="_blank" rel="noopener noreferrer">Berufsordnung der Landesärztekammer Bayern</a><br />
<a href="//www.gesetze-bayern.de/Content/Document/BayHKaG/True" target="_blank" rel="noopener noreferrer">Heilberufe-Kammergesetz des Landes Bayern</a>
</p>

<h4>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h4>
<p>Th. Funk &amp; Sohn GmbH <br />
Funk Ärzte Service <br />
Postfach 30 17 60 <br />
20306 Hamburg</p>

<h4>EU-Streitschlichtung</h4>
<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

<h4>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h4>
<p>Ich bin nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>   
           
           
            
            
            
          
            
           






          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
