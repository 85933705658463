
import React, {  } from 'react';

import './custom.css';
import $ from 'jquery';
function ContactForm() {
	function Contact_ () {
 // init the validator
    // validator files are included in the download package
    // otherwise download from http://1000hz.github.io/bootstrap-validator

    $('#contact-form').validator();


    // when the form is submitted
    $('#contact-form').on('submit', function (e) {

        // if the validator does not prevent form submit
        if (!e.isDefaultPrevented()) {
            var url = "contact-2.php";

            // POST values in the background the the script URL
            $.ajax({
                type: "POST",
                url: 'contact-2.php',
                data: $(this).serialize(),
                success: function (data)
                {
                    // data = JSON object that contact.php returns

                    // we recieve the type of the message: success x danger and apply it to the 
                    var messageAlert = 'alert-' + data.type;
                    var messageText = data.message;

                    // let's compose Bootstrap alert box HTML
                    var alertBox = '<div class="alert ' + messageAlert + ' alert-dismissable"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>' + messageText + '</div>';
                    
                    // If we have messageAlert and messageText
                    if (messageAlert && messageText) {
                        // inject the alert to .messages div in our form
                        $('#contact-form').find('.messages').html(alertBox);
                        // empty the form
                        $('#contact-form')[0].reset();
						}
					}
				});
				return false;
			}
		})
	};

	return (
		<div>
			<form id="contact-form" method="post" action="contact-2.php" role="form">

<div class="messages"></div>

<div class="controls">

	<div class="row">
		<div class="col-md-6">
			<div class="form-group">
				<label for="firma">Firma *</label>
				<input id="firma" type="text" name="firma" class="form-control" placeholder="Bitte Name der Firma angeben *" required="required" data-error="Bitte Name der Firma angeben."/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label for="ansprechpartner">Ansprechpartner *</label>
				<input id="ansprechpartner" type="text" name="ansprechpartner" class="form-control" placeholder="Bitte Ansprechpartner angeben *" required="required" data-error="Bitte Ansprechpartner angeben."/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
	</div>



	<div class="row">
		<div class="col-md-6">
			<div class="form-group">
				<label for="email">Email *</label>
				<input id="email" type="text" name="email" class="form-control" placeholder="Bitte Email angeben *" required="required" data-error="Bitte Email angeben."/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
		<div class="col-md-6">
		<div class="form-group">
				<label for="branche">Branchenzugehörigkeit / Gewerbeart</label>
				<input id="branche" type="text" name="branche" class="form-control" placeholder=""/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<div class="form-group">
				<label for="sonstige_kontaktinformationen">Sonstige Kontaktinformationen (z.B. Telefonnummer, wann an
                    besten ereichbar)</label>
				<textarea id="sonstige_kontaktinformationen" name="sonstige_kontaktinformationen" class="form-control" placeholder="" rows="4" data-error=""></textarea>
				<div class="help-block with-errors"></div>
			</div>
		</div>
		</div>










	<div class="row">

		<div class="col-md-12">
		<div class="form-group">
				<label for="WZ_Schluessel">WZ-Schlüssel Ihres Unternehmens. Ist der WZ-Schlüssel Ihres
                    Unternehmens nicht bekannt, so können Sie diesen z.B.
                    <a
                      href="https://www.dguv.de/medien/inhalt/praevention/vorschriften_regeln/dguv-vorschrift_2/downloads/wz_liste.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >in dieser Wirtschaftszweig-Liste der DGUV </a>nachschauen</label>
				<input id="WZ_Schluessel" type="text" name="WZ_Schluessel" class="form-control" placeholder="" data-error=""/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
	</div>



	<div class="row">
		<div class="col-md-12">
		<div class="form-group">
				<label for="mitarbeiter_anzahl">Anzahl der Mitarbeiter-/innen (einschließlich Voll- und
                Teilzeitkräfte, geringfügig Beschäftigte, Zeitarbeiter)</label>
				<textarea id="mitarbeiter_anzahl" name="mitarbeiter_anzahl" class="form-control" placeholder="" rows="3"/>
				<div class="help-block with-errors"></div>
			</div>
		</div>

	</div>





	<div class="row">
	<div class="col-md-6">
		<div class="form-group">
				<label for="betreuungsmodell">Gewünschte Betreuungsmodell</label>
				<input id="betreuungsmodell" type="text" name="betreuungsmodell" class="form-control" placeholder=""  data-error=""/>
				<div class="help-block with-errors"></div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="form-group">
				<label for="startdatum">Datum gewünschte Betreuungsbeginn</label>
				<input id="startdatum" type="date" name="startdatum" class="form-control" placeholder=""/>
				<div class="help-block with-errors"></div>
			</div>
		</div>

	</div>

	<div class="row">
		<div class="col-md-12">
			<div class="form-group">
				<label for="nachricht">Sonstige Anmerkungen/ Nachricht *</label>
				<textarea id="nachricht" name="nachricht" class="form-control" placeholder="Sonstige Anmerkungen/ Nachricht *" rows="4" required="required" data-error="Please,leave us a message."/>
				<div class="help-block with-errors"></div>
			</div>
		</div>



		<div class="col-md-12">
			<input type="submit" class="btn btn-outline-primary rounded-pill shadow-none" value="Absenden"/>
		</div>
	</div>
	<div class="row">
		<div class="col-md-12">
			<p class="text-muted"><strong>*</strong> Diese Felder werden benötigt.</p>
		</div>
	</div>
</div>

</form>
		</div>
	);
}

export default ContactForm;