import React from "react";
const AboutUs = ({ classicHeader, darkTheme }) => {
  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            Betriebsarzt
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            Über mich
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="col-lg-7 col-xl-8 text-center text-lg-start">
            {/* <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              Mein name ist  <span className="text-primary">Dr. Christian Zilz,</span> Betriebsarzt in Ottobrunn
            </h2>*/}
            <p className={darkTheme ? "text-white-50" : ""}>
            Die Arbeitsmedizin stützt sich auf eine ganzheitliche Betrachtung 
            des arbeitenden Menschen mit Berücksichti­gung somatischer, psychischer und sozialer Prozesse.
            Mit den zwei Fachärzten für Arbeitsmedizin und Innere Medizin und der Zusatzbezeichnung Notfallmedizin kann 
            ich ein weites Spektrum dieser ganzheitlichen Betrachtung abdecken. Ergänzend bildete ich mich in der Reisemedizin fort und besitze 
            die Genehmigung zur Durchführung von Hautkrebsscreening und Psychosomatische Grundversorgung der Kassenärzlichen Vereinigung Bayern. 
            Im Rahmen der Weiterbildung zum Facharzt für Arbeitsmedizin am Institut und in der Poliklinik für Arbeits-, Sozial- und Umweltmedizin 
            des Klinikums der Universität München und dem Betriebsärzlichen Dienst des Klinikums der LMU konnte ich ein breites Spektrum der Arbeitsmedizin erfahren. 
            Neben den Routinevorsorgen konnte ich in der arbeitsmedizinischen Ambulanz viele verschiedene berufsbedingte Erkrankungen kennen lernen. 
            Zusätzlich wirkte ich unter der Verantwortung meines Weiterbilders Prof. Nowak bei einer Vielzahl an arbeitsmedizinischen Fachgutachten mit. Seit Oktober 2022 bin ich als Juniorpartner in eigener <a href="https://www.kramer-zilz.de" target="_blank" rel="noreferrer" title="Hausarztpraxis in Ottobrunn Dres. Kramer-Zilz und Zilz">Hausarztpraxis</a> in Ottobrunn tätig.
            </p>
  
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          <div className="col-lg-5 col-xl-4">
            <div className="ps-lg-4">
              <ul
                className={
                  "list-style-2 " +
                  (darkTheme ? "list-style-light text-light" : "")
                }
              >
                <li>
                  <span className="fw-600 me-2">Name:</span>Dr. med. Christian Zilz
                </li>
                <li>
                  <span className="fw-600 me-2">Weiterbildung:</span>Facharzt für Arbeitsmedizin, Facharzt für Innere Medizin, Notfallmedizin, Reisemedizin
                </li>
                <li>
                  <span className="fw-600 me-2">Email:</span>
                  <a href="mailto:info@drzilz.de">info@drzilz.de</a>
                </li>
                
                <li className="border-0">
                  <span className="fw-600 me-2">Standort:</span>Ottobrunn,
                  Bayern
                </li>
              </ul>
            </div>
          </div>
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }

                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Years Experiance
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>250</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Happy Clients
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>650</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Projects Done
                </p>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>38</span>
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  Get Awards
                </p>
              </div>
            </div>
          </div>
        </div>*/}
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
