import React, { useRef, useState } from "react";
import ContactForm from './ContactForm';

const Contact = ({ classicHeader, darkTheme }) => {
  const form = useRef();

  return (
    <>
    <section id="contact" className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2 className={"text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")}>Kontakt</h2>
          <p className={"text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")}>Anfrage <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" /></p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* contact details */}
          <div className="col-md-4 col-xl-3 order-1 order-md-0 text-center text-md-start">
            <h2 className={"mb-3 text-5 text-uppercase " + (darkTheme ? "text-white" : "")}>Addresse</h2>
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>Ganghoferstr. 7,<br />85521 Ottobrunn</p>
            <p className={"text-3 mb-1 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-phone" /></span>(089) 22 84 84 14</p>
           
            <p className={"text-3 mb-4 " + (darkTheme ? "text-light" : "")}>
              <span className="text-primary text-4 me-2">
                <i className="fas fa-envelope" /></span>info@drzilz.de</p>
            </div>
            {/*             
          {/* contact form */}
          <div className="col-md-8 col-xl-9 order-0 order-md-1">
            <h2
              className={"mb-3 text-5 text-uppercase text-center text-md-start " +
                (darkTheme ? "text-white" : "")}
            >
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>Sie möchten ein unverbindliches Angebot über betriebsärztliche oder arbeitsmedizinische Betreuung Ihres Unternehmens erhalten?
              Ich freue mich auf Ihre Anfrage!</p>
           <ContactForm /> 
          </div>




        


















          
        </div>
      </div>

      </section>
      </>
  );
};
export default Contact;
